import React from 'react';
import useContentfulFaqs from '../hooks/useContentfulFaqs';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import AccordionList from '../components/AccordionList';
import { TextPreFooter } from '../components/PreFooter';
import Lottie from '../components/Lottie';
import styles from './styles/faqs.module.scss';
import PageHeader from '../components/sections/PageHeader';

const FAQs = () => {
  const items = useContentfulFaqs();
  const headerImage = (
    <div className={styles.homeImgWrap}>
      <Lottie className={styles.car} name="car" />
      <Lottie className={styles.house} name="house" />
      <Lottie className={styles.dog} name="dog_V02" />
      <Lottie className={styles.q1} name="Q_dance_V02" />
    </div>
  );

  return (
    <Layout transparent>
      <SEO
        description="Quashed is an all-in-one insurance platform making it easy for Kiwis to sort out all our insurance in one place. Whether you’re exploring insurance, looking to compare and find the best insurance for you, wanting to manage all your existing insurance on one dashboard, or chat to an insurance expert, Quashed can help. We’re NZ’s first and only platform that do what we do. And, it’s free!"
        title="FAQs"
      />
      <PageHeader
        title="FAQs"
        subtitle="Frequently asked Quashed-tions. (sorry)"
        image={headerImage}
        wrapClassName={styles.pageHeader}
      ></PageHeader>
      <div className={styles.container}>
        <AccordionList items={items} />
      </div>
      <TextPreFooter
        subTitleTop="Quashed"
        title="Be the boss of your insurance today"
      />
    </Layout>
  );
};

export default FAQs;
