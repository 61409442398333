import { INLINES } from '@contentful/rich-text-types';
import { useStaticQuery, graphql } from 'gatsby';
import { getRichTextFromQuery } from '../common/utils';
import ContentfulLinks from '../components/ContentfulLinks';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: ContentfulLinks,
  },
};

const useContentfulFaqs = () => {
  const { allContentfulFaq } = useStaticQuery(
    graphql`
      {
        allContentfulFaq(sort: { order: ASC, fields: order }) {
          edges {
            node {
              order
              anchor
              title
              showOnHomePage
              description {
                json
              }
            }
          }
        }
      }
    `,
  );
  return getRichTextFromQuery(allContentfulFaq.edges, options);
};

export default useContentfulFaqs;
